/*
We're using external application forms that uses css-vars for styling
Create variables here to keep styling consistent in both our own forms and buttons and the external party's forms and buttons
*/
:root {
  --font-size-button-default: var(--font-size-16);
  --font-weight-button: var(--font-weight-medium);
  --letter-spacing-button: var(--font-letterspacing-sm);
  --padding-button-default: var(--size-16) var(--size-24);
  --border-radius-button: var(--border-radius-pill);
  --border-form-field-default: var(--border-medium);

  --bg-color-button-primary: var(--bg-color-orange);
  --bg-color-button-primary-hover: var(--bg-color-orange-hover);
  --text-color-button-primary: var(--text-color-white);

  --bg-color-button-secondary: var(--bg-color-blue-dark);
  --bg-color-button-secondary-hover: var(--bg-color-blue-dark-hover);
  --text-color-button-secondary: var(--text-color-white);

  --bg-color-button-tertiary: var(--bg-color-white);
  --bg-color-button-tertiary-hover: var(--bg-color-white-hover);
  --text-color-button-tertiary: var(--text-color-default);

  --font-size-form-default: var(--font-size-16);

  --border-radius-form-field-default: var(--border-radius-8);
  --border-color-form-field-default: var(--color-blue-300);

  --placholder-color-form-field-default: var(--color-blue-400);

  /* input text */
  --font-size-input-default: var(--font-size-16);
  --padding-input-default: var(--size-12) var(--size-24);

  --border-color-input-primary: var(--color-white);
  --border-color-input-primary-focus: var(--color-blue-700);
  --bg-color-input-primary: var(--color-white);

  --border-color-input-secondary: var(--color-white);
  --border-color-input-secondary-focus: var(--color-blue-700);
  --bg-color-input-secondary: var(--color-white);

  /* range slider */
  --bg-color-thumb-primary: var(--bg-color-orange);
  --bg-color-thumb-primary-hover: var(--bg-color-orange-hover);
  --bg-color-track-primary: var(--bg-color-orange);
  --bg-color-slider-primary: var(--color-blue-300);
  --bg-color-label-primary: var(--color-white);
  --outline-color-thumb-primary: var(--color-blue-100);

  --bg-color-thumb-secondary: var(--bg-color-orange);
  --bg-color-thumb-secondary-hover: var(--bg-color-orange-hover);
  --bg-color-track-secondary: var(--bg-color-orange);
  --bg-color-slider-secondary: var(--color-blue-300);
  --bg-color-label-secondary: var(--color-blue-300);
  --outline-color-thumb-secondary: var(--color-white);

  /* select */
  --padding-select: var(--size-12) var(--size-48) var(--size-12) var(--size-24);
  --arrow-offset-select: var(--size-20);

  --border-color-select-primary: var(--border-color-blue-dark);
  --bg-color-select-primary: var(--bg-color-white);

  --border-color-select-secondary: var(--border-color-white);
  --border-color-select-secondary-focus: var(--border-color-blue-dark);
  --bg-color-select-secondary: var(--bg-color-white);

  --border-color-select-tertiary: var(--border-color-blue-light);
  --border-color-select-tertiary-focus: var(--border-color-blue-dark);
  --bg-color-select-tertiary: var(--bg-color-white);

  /* checkbox */
  --border-color-checkbox-primary: var(--color-blue-500);
  --border-color-checkbox-primary-focus: var(--border-color-blue-dark);
  --bg-color-checkbox-primary: var(--bg-color-white);
  --color-checkbox-primary: var(--color-blue-700);

  --color-filter-checkbox-checked: var(--bg-color-orange);

  --border-color-checkbox-secondary: var(--border-color-white);
  --bg-color-checkbox-secondary: var(--bg-color-white);
  --border-color-checkbox-secondary-focus: var(--border-color-blue-dark);
  --color-checkbox-secondary: var(--color-blue-700);
  --color-checkbox-secondary-checked: var(--color-blue-700);
}
