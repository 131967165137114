/*
Vars for styling Ubeeo form. Vars are documented here: https://drive.google.com/drive/u/0/folders/1qs4vi1-fW7KEMslzyiFVfzlldo_NoLb9
Note that this only works when vars are declared at :root, since some values will be expanded on the html element
*/
:root {
  /* Primary color of website, used for various elements. */
  --ubPrimaryColor: var(--color-primary);

  /* Default font-family used for all elements, more specific variables are available. */
  --ubMainFontSize: var(--font-size-form-default);

  /* font-size of main container, all other font sizes are set to EM unites. Should be roughly equal to the default font size for text on your pages. */
  --ubFontFamily: var(--font-family-base);

  /* button vars */
  --ubButtonFontSize: var(--font-size-button-default);
  --ubButtonFontWeight: var(--font-weight-button);
  --ubButtonPadding: var(--size-12) var(--size-24);
  --ubButtonBorderRadius: var(--border-radius-button);

  --ubPrimaryButtonColor: var(--bg-color-button-primary);
  --ubPrimaryButtonHoverColor: var(--bg-color-button-primary-hover);
  --ubPrimaryButtonTextColor: var(--text-color-button-primary);

  --ubSecondaryButtonColor: var(--bg-color-button-secondary);
  --ubSecondaryButtonHoverColor: var(--bg-color-button-secondary-hover);
  --ubSecondaryButtonTextColor: var(--text-color-button-secondary);

  /* Heading vars */
  --ubHeaderFontWeight: var(--font-weight-medium);
  --ubH1FontSize: var(--font-size-32-48);
  --ubH2FontSize: var(--font-size-48);

  --navColor: var(--color-secondary);

  /* form */
  /* Set form so that labels and inputs are displayed in full width. Use value 0/1 to toggle */
  --ubFormFullWidth: 1;
  --ubInputBorderRadius: var(--border-radius-form-field-default);
  --ubFormLineHeight: var(--line-height-heading-default);
  --ubPlaceholderColor: var(--color-blue-600);

  /* form help texts */
  /* info text bg color */
  --ubInfoColorLight: var(--bg-color-blue-lighter);
  --ubInfoColorDark: var(--text-color-default);

  /* color for labels and borders of fields with error, and error-message border */
  --ubErrorColorDark: var(--color-red-400);

  /* other available css vars */

  /* Light grey used for various elements. Sufficient contrast ratio. */
  /* --ubLightGray: #767676 */
  /* Default border-radius used for elements. */
  /* --ubBorderRadius: 3px */
  /* text-decoration of links */
  /* --ubLinkTextDecoration */
  /* text-decoration of links on hover */
  /* --ubLinkTextDecorationHover */
  /* --ubButtonBorderRadius: var(--ubBorderRadius) */
  /* --ubButtonFontFamily: var(--ubFontFamily) */
  /* --ubButtonBorder: none */
  /* --ubButtonTextTransform: none */
  /* Set extra padding on container around form */
  /* --ubFormOnPagePadding: 0 */

  /* --ubWarnColorLight */
  /* --ubWarnColorDark */
  /* --ubConfirmColorLight */
  /* --ubConfirmColorDark */
  /* --ubErrorColorLight */
}
