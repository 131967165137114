.componentBase {
  --generic-max-width: calc(100% - 2 * var(--container-padding-inline));
  --container-max-width: 100%;

  margin-inline: auto;
  width: min(var(--container-max-width), var(--generic-max-width));
}

.componentSm {
  --container-max-width: var(--container-max-width-sm);
}

.componentMd {
  --container-max-width: var(--container-max-width-md);
}

.componentLg {
  --container-max-width: var(--container-max-width-lg);
}

.componentXl {
  --container-max-width: var(--container-max-width-xl);
}
