:root {
  --border-radius-8: 0.5rem;
  --border-radius-24: 1.5rem;
  --border-radius-32: 2rem;
  --border-radius-pill: 100rem;
  --border-radius-circle: 50%;

  --border-width-medium: var(--size-2);
  --border-width-bold: var(--size-4);

  --border-medium: var(--border-width-medium) solid;
  --border-bold: var(--border-width-bold) solid;
}
