.componentBase,
.componentButtonBase {
  --icon-bg-color: var(--bg-color-orange);

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-button-default);
  line-height: var(--line-height-button-default);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--font-letterspacing-sm);
  color: var(--text-color-default);
  font-size: var(--font-size-16);

  &:hover,
  &:focus-visible {
    --icon-bg-color: var(--bg-color-orange-hover);

    text-decoration: underline;
  }

  & > .IconElementLayout {
    flex-shrink: 0;

    &.iconSizeSm {
      width: var(--icon-circle-size-32);
    }

    &.iconSizeMd {
      width: var(--icon-circle-size-48);
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  &:active {
    scale: 0.96;
  }
}

.componentIconElement {
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  color: var(--icon-color-white);
  background-color: var(--icon-bg-color);
  border-radius: var(--border-radius-circle);
  transition: var(--duration-300) linear;
  transition-property: background-color, color;
}

.iconSizeSm {
  & > .iconLayout {
    width: var(--icon-size-18);
  }
}

.iconSizeMd {
  & > .iconLayout {
    width: var(--icon-size-24);
  }
}
