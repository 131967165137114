.componentBase {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-heading-default);
  line-height: var(--line-height-heading-default);
  color: var(--heading-text-color-default);
}

.size24 {
  font-size: var(--font-size-24);
}

.size32 {
  font-size: var(--font-size-32);
}

.size40 {
  font-size: var(--font-size-40);
}

.size48 {
  font-size: var(--font-size-48);
}

.size32to40 {
  font-size: var(--font-size-32-40);
}

.size32to48 {
  font-size: var(--font-size-32-48);
}

.size32to64 {
  font-size: var(--font-size-32-64);
}

.size40to48 {
  font-size: var(--font-size-40-48);
}

.size48to78 {
  font-size: var(--font-size-48-78);
}

.size32to96 {
  font-size: var(--font-size-32-96);
}

.size48to96 {
  font-size: var(--font-size-48-96);
}

.colorWhite {
  color: var(--text-color-white);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorAccent {
  color: var(--color-accent);
}

.fontWeightRegular {
  font-weight: var(--font-weight-regular);
}

.fontWeightLight {
  font-weight: var(--font-weight-light);
}

.fontWeightBold {
  font-weight: var(--font-weight-bold);
}
