.page {
  padding-top: var(--size-36);

  @media (--viewport-lg) {
    padding-top: var(--size-72);
  }

  & > .content {
    max-width: var(--size-content-45);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}
